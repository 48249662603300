import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TextInput = ({ label, disabled, value, ...props }) => {
  return (
    <TextField
      className="text-field-component"
      disabled={disabled}
      label={label}
      value={value}
      {...props}
      InputLabelProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
        shrink: true,
      }}
    />
  );
};

export default memo(TextInput);
