import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import App from "./App";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => {
    return <App />;
  },
  errorBoundary(err) {
    // Customize the root error boundary for your microfrontend here.
    //return null;
    console.error(err);
    return <div>Something went wrong! Check the console for errors</div>;
  },
});
export const { bootstrap, mount, unmount } = lifecycles;
