function flattenMatterDtoList(arr) {
  return arr.map((item) => {
    if (item.matterDtoList) {
      const matterDto = item.matterDtoList[0];
      const billAttornyDto = item.matterDtoList[0].billAttornyDto;

      return {
        ...item,
        ...matterDto,
        ...billAttornyDto,
      };
    }
    return item;
  });
}

export default flattenMatterDtoList;
