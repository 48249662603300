import React, { useState, useEffect, useMemo } from "react";
import DataTable from "../../../Components/DataGrid";
import ToastMessage from "../../../Components/ToastMessage";
import GridPagination from "../../../Components/CustomPagination/GridPagination";
import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import BillingModal from "../BillingModal";
import { useQuery } from "@apollo/client";
import { GET_ALL_BILL_ENTRIES } from "../graphql/GET_ALL_BILL_ENTRIES";
import dayjs from "dayjs";
import flattenMatterDtoList from "../../../utils/flatten";
import { MESSAGE_TYPE } from "../../../utils/enum";
import _ from "lodash";

const initialEmptyRow = {
  id: "",
  billDate: "",
  billStatusDesc: "",
  clientName: "",
  clientCode: "",
  jobName: "",
  jobCode: "",
  billAttrCode: "",
  ownerID: "",
  amountBilled: "",
  amountPaid: "",
  amountAdjusted: "",
  balance: "",
  hasExpenseActivity: "",
  hasFeeActivity: "",
  hasPaymentActivity: "",
};

const index = () => {
  const { t } = useTranslation();
  const [isBillModalOpen, setBillModalOpen] = useState(false);
  const [billModalId, setBillModalId] = useState("");

  const [activityAllData, setActivityAllData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const [filter, setFilter] = useState(initialEmptyRow);
  const [statusFilter, setStatusFilter] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const { data, error, loading } = useQuery(GET_ALL_BILL_ENTRIES);

  useEffect(() => {
    if (data?.getAllBillEntries?.length > 0) {
      setActivityData(flattenMatterDtoList(data?.getAllBillEntries));
      setActivityAllData(flattenMatterDtoList(data?.getAllBillEntries));
    }
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error, data, loading]);

  // eslint-disable-next-line
  const handleSearch = useMemo(() => {
    let filteredData = activityAllData;
    // Filter by ID
    if (filter.id !== "") {
      filteredData = filteredData.filter((activity) => activity.id.toString().toLowerCase().includes(filter.id.toLowerCase()));
    }

    // Filter by Date
    if (filter.billDate !== "") {
      filteredData = filteredData.filter((activity) => activity.billDate.toLowerCase() === filter.billDate.toLowerCase());
    }

    // Filter by Status
    if (filter.billStatusDesc !== "") {
      filteredData = filteredData.filter((activity) => activity.billStatusDesc.toLowerCase() === filter.billStatusDesc.toLowerCase());
    }
    if (statusFilter !== "") {
      filteredData = filteredData.filter((activity) => activity.billStatusDesc.toLowerCase() === statusFilter.toLowerCase());
    }

    // Filter by Client
    if (filter.clientName !== "") {
      filteredData = filteredData.filter(
        (activity) =>
          (activity.clientName && activity.clientName.toLowerCase().includes(filter.clientName.toLowerCase())) ||
          (activity.clientCode && activity.clientCode.toLowerCase().includes(filter.clientName.toLowerCase())),
      );
    }

    // Filter by Matter
    if (filter.jobName !== "") {
      filteredData = filteredData.filter(
        (activity) =>
          (activity.jobName && activity.jobName.toLowerCase().includes(filter.jobName.toLowerCase())) ||
          (activity.jobCode && activity.jobCode.toLowerCase().includes(filter.jobName.toLowerCase())),
      );
    }

    // Filter by Bill Attorney
    if (filter.billAttrCode !== "") {
      filteredData = filteredData.filter((activity) => activity.billAttrCode.toLowerCase().includes(filter.billAttrCode.toLowerCase()));
    }

    // Filter by Owner
    if (filter.ownerID !== "") {
      filteredData = filteredData.filter((activity) => activity.ownerID.toString().toLowerCase().includes(filter.ownerID.toString().toLowerCase()));
    }

    // Filter by Billed
    if (filter.amountBilled !== "") {
      filteredData = filteredData.filter((activity) =>
        activity.amountBilled.toString().toLowerCase().includes(filter.amountBilled.toString().toLowerCase()),
      );
    }

    // Filter by Paid
    if (filter.amountPaid !== "") {
      filteredData = filteredData.filter((activity) => activity.amountPaid.toString().toLowerCase().includes(filter.amountPaid.toLowerCase()));
    }

    // Filter by Adjusted
    if (filter.amountAdjusted !== "") {
      filteredData = filteredData.filter((activity) =>
        activity.amountAdjusted.toString().toLowerCase().includes(filter.amountAdjusted.toLowerCase()),
      );
    }

    // Filter by Balance
    if (filter.balance !== "") {
      filteredData = filteredData.filter((activity) => activity.balance.toString().toLowerCase().includes(filter.balance.toLowerCase()));
    }

    // Filter by Fees Available
    if (filter.hasExpenseActivity !== "") {
      filteredData = filteredData.filter((activity) => activity.hasExpenseActivity.toString() === filter.hasExpenseActivity);
    }

    // Filter by Expenses
    if (filter.hasFeeActivity !== "") {
      filteredData = filteredData.filter((activity) => activity.hasFeeActivity.toString() === filter.hasFeeActivity);
    }

    // Filter by Payments
    if (filter.hasPaymentActivity !== "") {
      filteredData = filteredData.filter((activity) => activity.hasPaymentActivity.toString() === filter.hasPaymentActivity);
    }

    setActivityData(filteredData);
  }, [filter, statusFilter]);

  const handleSearchInput = useMemo(() => {
    return _.debounce((e) => {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [e.target.name]: e.target.value.trim(),
      }));
    }, 500);
  }, []);

  const handleStatusFilter = useMemo(() => {
    return (value) => setStatusFilter(() => value.trim());
  }, []);

  const columns = [
    {
      field: "id",
      headerName: t("ID"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? <input type="number" className="grid-filter" name="id" onChange={handleSearchInput} /> : params.row.id;
      },
    },
    {
      field: "billDate",
      headerName: t("Bill Date"),
      minWidth: 125,
      renderCell: (params) => {
        return params.id === "" ? (
          <input type="date" className="grid-filter" name="billDate" onChange={handleSearchInput} />
        ) : (
          dayjs(params.row.billDate).format("DD/MM/YYYY")
        );
      },
    },
    {
      field: "billStatusDesc",
      headerName: t("Status"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <select className="grid-filter" name="billStatusDesc" onChange={handleSearchInput}>
            <option value=""></option>
            <option value="Draft">Draft</option>
            <option value="Locked">Locked</option>
            <option value="Finalized">Finalized</option>
          </select>
        ) : (
          params.row.billStatusDesc
        );
      },
    },
    {
      field: "clientName",
      headerName: t("Client"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input className="grid-filter" name="clientName" onChange={handleSearchInput} />
        ) : (
          <div className="ellipsis-text" title={params.row.clientName}>
            {params.row.clientCode} {params.row.clientName}
          </div>
        );
      },
    },
    {
      field: "jobName",
      headerName: t("Matter"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input className="grid-filter" name="jobName" onChange={handleSearchInput} />
        ) : (
          <div className="ellipsis-text" title={params.row.jobName}>
            {params.row.jobCode} {params.row.jobName}
          </div>
        );
      },
    },
    {
      field: "billAttrCode",
      headerName: t("Bill Attorney"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input className="grid-filter" name="billAttrCode" onChange={handleSearchInput} />
        ) : (
          <div className="ellipsis-text" title={params.row.billAttrCode}>
            {params.row.billAttrCode}
          </div>
        );
      },
    },
    {
      field: "ownerID",
      headerName: t("Owner"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input className="grid-filter" name="ownerID" onChange={handleSearchInput} />
        ) : (
          <div className="ellipsis-text" title={params.row.ownerID}>
            {params.row.ownerID}
          </div>
        );
      },
    },
    {
      field: "amountBilled",
      headerName: t("Billed"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input type="number" className="grid-filter" name="amountBilled" onChange={handleSearchInput} />
        ) : (
          `$${params.row.amountBilled}`
        );
      },
    },
    {
      field: "amountPaid",
      headerName: t("Paid"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input type="number" className="grid-filter" name="amountPaid" onChange={handleSearchInput} />
        ) : (
          `$${params.row.amountPaid}`
        );
      },
    },
    {
      field: "amountAdjusted",
      headerName: t("Adjusted"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input type="number" className="grid-filter" name="amountAdjusted" onChange={handleSearchInput} />
        ) : (
          `$${params.row.amountAdjusted}`
        );
      },
    },
    {
      field: "balance",
      headerName: t("Balance"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <input type="number" className="grid-filter" name="balance" onChange={handleSearchInput} />
        ) : (
          `$${params.row.balance}`
        );
      },
    },
    {
      field: "hasExpenseActivity",
      headerName: t("Fees Available"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <select className="grid-filter" name="hasExpenseActivity" onChange={handleSearchInput}>
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        ) : params.row.hasExpenseActivity ? (
          "Yes"
        ) : (
          "No"
        );
      },
    },
    {
      field: "hasFeeActivity",
      headerName: t("Expenses Avail"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <select className="grid-filter" name="hasFeeActivity" onChange={handleSearchInput}>
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        ) : params.row.hasFeeActivity ? (
          "Yes"
        ) : (
          "No"
        );
      },
    },
    {
      field: "hasPaymentActivity",
      headerName: t("Payments Avail"),
      minWidth: 120,
      renderCell: (params) => {
        return params.id === "" ? (
          <select className="grid-filter" name="hasPaymentActivity" onChange={handleSearchInput}>
            <option value=""></option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        ) : params.row.hasPaymentActivity ? (
          "Yes"
        ) : (
          "No"
        );
      },
    },
  ];

  return (
    <>
      <Box className="pending-activity-box">
        <Box className="activity-grid-filter">
          <Button variant="outlined" className={`btn btn-small ${statusFilter === "" && "active"}`} onClick={() => handleStatusFilter("")}>
            All
          </Button>
          <Button variant="outlined" className={`btn btn-small ${statusFilter === "Draft" && "active"}`} onClick={() => handleStatusFilter("Draft")}>
            Draft
          </Button>
          <Button
            variant="outlined"
            className={`btn btn-small ${statusFilter === "Locked" && "active"}`}
            onClick={() => handleStatusFilter("Locked")}>
            Locked
          </Button>
          <Button
            variant="outlined"
            className={`btn btn-small ${statusFilter === "Finalized" && "active"}`}
            onClick={() => handleStatusFilter("Finalized")}>
            Finalized
          </Button>
        </Box>

        <DataTable
          loader={loading}
          columns={columns ?? []}
          rows={activityData ?? []}
          components={{
            Pagination: GridPagination,
            NoRowsOverlay: () => {
              if (loading) {
                return <CircularProgress />;
              } else {
                return <>No data found!</>;
              }
            },
          }}
          checkboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          onCellDoubleClick={(params) => {
            if (params.id !== "") {
              setBillModalOpen(true);
              setBillModalId(params.id);
            }
          }}
          isRowSelectable={(params) => params.id !== ""}
          pinnedRows={{
            top: [initialEmptyRow],
          }}></DataTable>
      </Box>

      {isBillModalOpen && (
        <BillingModal billId={billModalId} open={isBillModalOpen} handleClose={() => setBillModalOpen(false)} setErrorMessage={setErrorMessage} />
      )}

      <ToastMessage message={errorMessage} type={MESSAGE_TYPE.ERROR} autoHideDuration={3000} onClose={() => setErrorMessage("")} />
    </>
  );
};

export default index;
