import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { MESSAGE_TYPE } from "../../utils/enum";

const ToastMessage = ({ message, type = MESSAGE_TYPE.ERROR, onClose, autoHideDuration = 4000 }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={!!message} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ToastMessage;
