import React from "react";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

export const OWNER_TABS = {
  OWNER_DETAILS: { label: "Owner Details", value: "OWNER_DETAILS" },
  REVIEW_NOTES: { label: "Review Notes", value: "REVIEW_NOTES" },
  DIARY_MESSAGES: { label: "Diary Messages", value: "DIARY_MESSAGES" },
};

export const BILL_TABS = {
  BILL_DETAILS: "Bill Details",
  FEES_AVAILABLE: "Fees Available",
  EXPENSE_AVAILABLE: "Expenses Available",
  PAYMENTS_AVAILABLE: "Payments Available",
};

export const ACTIVITY_TABS = {
  SUMMARY: { label: "Summary", value: "SUMMARY", icon: <ContentPasteIcon /> },
  WORKSHEET: { label: "Worksheet", value: "WORKSHEET", icon: <ViewHeadlineIcon /> },
};

export const MESSAGE_TYPE = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};
