import { gql } from "@apollo/client";

export const GET_ALL_BILL_ENTRIES = gql`
  query {
    getAllBillEntries(asOwner: false) {
      id
      customerBillId
      hasFeeActivity
      hasExpenseActivity
      hasPaymentActivity
      createdByID
      createdDate
      modifiedByID
      modifiedDate
      backColor
      revisionNumber
      billDate
      statusID
      eBillStatusTypeID
      createDate
      startDate
      cutoffDate
      targetDate
      customerID
      supervisorEmployeeID
      administratorEmployeeID
      reviewStatusID
      reviewNote
      ownerID
      reviewDate
      unpostBillCount
      eBillSystemNameID
      amountBilled
      amountPaid
      amountAdjusted
      amountTimeFeeBilled
      amountTaskFeeBilled
      amountSimpleFeeBilled
      amountExpenseBilled
      amountTimeFeeWorked
      amountTaskFeeWorked
      amountSimpleFeeWorked
      amountExpenseWorked
      amountDiscount
      taxAmount
      tax1Amount
      tax2Amount
      tax3Amount
      tax4Amount
      tax5Amount
      amountInterest
      amountSurcharge
      amountRetainer
      billRemarks
      billInstruction
      sentDate
      submitDate
      billFormatID
      referenceNumber
      billFrequencyID
      billOnDemand
      billPhaseID
      jobIDList
      billStatusDesc
      balance
      clientId
      clientCode
      clientName
      matterDtoList {
        jobId
        jobCode
        jobName
        billAttornyDto {
          billAttrId
          billAttrCode
          billAttrName
        }
      }
    }
  }
`;
