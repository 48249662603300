import { Box, Typography } from "@mui/material";
import React from "react";

const Header = () => {
  return (
    <Box className="header-box">
      <Typography className="main-header">Bill Approval</Typography>
    </Box>
  );
};

export default Header;
