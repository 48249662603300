export const currencyFormatter = (amount) => {
  const numberAmount = Number(amount);
  if (isNaN(numberAmount)) {
    return "-";
  }

  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
  }).format(numberAmount);

  return formattedAmount;
};
