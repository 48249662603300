const jsUtils = {
  parseHash: (hashUrl) => {
    return hashUrl
      ?.replace("#", "")
      .split("&")
      .reduce((prev, item) => {
        return Object.assign({ [item.split("=")[0]]: decodeURIComponent(item.split("=")[1]) }, prev);
      }, {});
  },
};

export default jsUtils;
