import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store";
import ApolloContextProvider from "./Graphql/ApolloContextProvider";
import PendingActivityGrid from "./pages/Billing/PendingActivityGrid";
import Header from "./Components/CommonComponent/Header";
import { Box } from "@mui/material";
import jsUtils from "./utils/jsUtils.js";

import "./styles.scss";
import "./styles/main.scss";

const App = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    const sid = jsUtils.parseHash(window.location.hash)?.["sid"];
    if (sid) {
      localStorage.setItem("sid", sid);
      window.history.replaceState({}, "", "/BillApproval");
      setUserLoggedIn(true);
    } else {
      if (localStorage.getItem("sid")) {
        window.history.replaceState({}, "", "/BillApproval");
        setUserLoggedIn(true);
      } else {
        console.log("no sid found");
      }
    }
  }, []);

  return (
    <ApolloContextProvider uri="/billing-management/graphql">
      <Provider store={store}>
        {userLoggedIn && (
          <Box className="billing-app" data-testid="billing-app-box">
            <Header />
            <PendingActivityGrid />
          </Box>
        )}
      </Provider>
    </ApolloContextProvider>
  );
};

export default App;
