import React, { useState, memo } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputAdornment, Tooltip } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import dayjs from "dayjs";

const DatePicker = ({ label, disabled, value }) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const handleDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        className="date-picket-component"
        label={label}
        name={label}
        value={dayjs(value)}
        open={openDatePicker}
        onClose={() => handleDatePicker()}
        slotProps={{
          textField: {
            InputLabelProps: { shrink: true },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Open Calendar">
                    <DateRangeIcon className="calender-icon" onClick={() => handleDatePicker()} />
                  </Tooltip>
                </InputAdornment>
              ),
            },
          },
        }}
        InputLabelProps={{ shrink: true }}
        size="small"
        inputFormat="MM/DD/YYYY"
        format="MM/DD/YYYY"
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default memo(DatePicker);
