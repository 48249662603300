import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, Divider, Grid, IconButton, Tab, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList } from "@mui/lab";
import { ACTIVITY_TABS, OWNER_TABS } from "../../../utils/enum";
import { useLazyQuery } from "@apollo/client";
import { GET_BILL_DETAILS } from "../graphql/GET_BILL_DETAILS";
import { currencyFormatter } from "../../../utils/currencyFormat";
import dayjs from "dayjs";
import Summary from "../../../Modules/BillDetails/Summary";
import BillDetail from "../../../Modules/BillDetails";

const BillingModal = ({ billId, open, handleClose }) => {
  const [ownerTab, setOwnerTab] = useState(OWNER_TABS.OWNER_DETAILS.value);
  const [activityTab, setActivityTab] = useState(ACTIVITY_TABS.SUMMARY.value);
  const [isBillDetailOpen, setBillDetailOpen] = useState(false);

  const [billDetails, setBillDetails] = useState(null);

  const [fetchBillDetails] = useLazyQuery(GET_BILL_DETAILS, {
    variables: {
      billId: billId,
    },
    onCompleted: (data) => {
      setBillDetails(data.getBillDetail);
    },
  });

  useEffect(() => {
    setOwnerTab(OWNER_TABS.OWNER_DETAILS.value);
    setBillDetailOpen(false);
    if (billId) {
      fetchBillDetails();
    }
  }, [billId, open]);

  return (
    <>
      <Dialog className="billing-modal" open={open} onClose={handleClose} maxWidth="xl" fullWidth={true}>
        <Box className="flex justify-between items-center bill-modal-heading">
          <span>
            Bill: [{billDetails?.id} Rev{billDetails?.billRevisionNum}]: {billDetails?.clientCode}/{billDetails?.matterDtoList?.[0]?.jobCode}{" "}
            {billDetails?.billDate ? dayjs(billDetails?.billDate).format("MM/DD/YYYY") : ""}
          </span>
          <Box className="flex justify-between items-center heading-right-info">
            <Box className="flex">
              <Button className="btn btn-large bill-total-btn">
                BILL TOTAL : {billDetails?.total ? currencyFormatter(billDetails?.total).replace("$", "$ ") : "-"}
              </Button>
              <Button className="btn btn-small submit-btn">Submit</Button>
            </Box>
            <Tooltip title={`Bill: [${billDetails?.id} Rev00]: 12481/21102 09/19/2023`}>
              <IconButton onClick={handleClose}>
                <CloseIcon className="icons" style={{ color: "#0050C3" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box className="bill-detail-section">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <BillDetail
                billDetails={billDetails}
                ownerTab={ownerTab}
                setOwnerTab={setOwnerTab}
                isBillDetailOpen={isBillDetailOpen}
                setBillDetailOpen={setBillDetailOpen}
              />
            </Grid>
          </Grid>
        </Box>
        {!isBillDetailOpen && <Divider />}
        <Box className="bill-activity-section">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TabContext value={activityTab}>
                <Box className="bill-tab-section" sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={(event, newValue) => setActivityTab(newValue)}>
                    {Object.values(ACTIVITY_TABS).map((item, index) => {
                      return <Tab key={index} label={item.label} value={item.value} icon={item.icon} iconPosition="start" />;
                    })}
                  </TabList>
                </Box>
                <Box className="bill-activity-content-section">
                  {activityTab === ACTIVITY_TABS.SUMMARY.value && <Summary />}
                  {activityTab === ACTIVITY_TABS.WORKSHEET.value && <Box>Worksheet</Box>}
                </Box>
              </TabContext>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default BillingModal;
