import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DataTable from "../../Components/DataGrid";
import { useTranslation } from "react-i18next";
import AlertDialog from "../../Components/DialogComponent/AlertDialog";
import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "../../Components/CommonComponent/TextField";
import DatePicker from "../../Components/CommonComponent/DatePicker";
import TextArea from "../../Components/CommonComponent/TextArea";

const Summary = () => {
  const { t } = useTranslation();
  const [isExportDialogOpen, setExportDialog] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialog] = useState(false);
  const [isFeeDialogOpen, setFeeDialog] = useState(false);

  const timekeeperColumns = [
    {
      field: "type",
      headerName: t("Type"),
      minWidth: 75,
    },
    {
      field: "name",
      headerName: t("Name"),
      minWidth: 120,
    },
    {
      field: "hours",
      headerName: t("Hours"),
      minWidth: 75,
    },
    {
      field: "worked",
      headerName: t("Worked"),
      minWidth: 75,
    },
    {
      field: "billed",
      headerName: t("Billed"),
      minWidth: 75,
    },
    {
      field: "attorney",
      headerName: t("Bill Attorney"),
      minWidth: 75,
    },
    {
      field: "discount",
      headerName: t("Discount"),
      minWidth: 75,
    },
    {
      field: "charge",
      headerName: t("No Charge"),
      minWidth: 75,
    },
    {
      field: "writeOff",
      headerName: t("Write Off"),
      minWidth: 75,
    },
  ];

  const timeKeeperData = [
    {
      id: 1,
      type: "Time Fee",
      name: "Spikes Scylina P ",
      hours: "2.00",
      worked: "5.17.50",
      billed: "900.00",
      attorney: "SSP",
      discount: "0.00",
      charge: "No",
      writeOff: "No",
    },
    {
      id: 2,
      type: "Time Fee",
      name: "Spikes Scylina P ",
      hours: "2.00",
      worked: "5.17.50",
      billed: "900.00",
      attorney: "SSP",
      discount: "0.00",
      charge: "No",
      writeOff: "No",
    },
    {
      id: 3,
      type: "Time Fee",
      name: "Spikes Scylina P ",
      hours: "2.00",
      worked: "5.17.50",
      billed: "900.00",
      attorney: "SSP",
      discount: "0.00",
      charge: "No",
      writeOff: "No",
    },
  ];

  const chargesColumns = [
    {
      field: "type",
      headerName: t(""),
      minWidth: 125,
      renderCell: (props) => {
        if (props.formattedValue === "Discount" || props.formattedValue === "Surcharge" || props.formattedValue === "Interest") {
          return (
            <Button variant="contained" className="btn btn-chargesTable">
              {props.formattedValue}
            </Button>
          );
        }

        return <h1 className="font-bold">{props.formattedValue}:</h1>;
      },
    },
    {
      field: "worked",
      headerName: t("Worked ($)"),
      minWidth: 125,
    },
    {
      field: "billed",
      headerName: t("Billed ($)"),
      minWidth: 125,
    },
    {
      field: "variance",
      headerName: t("Variance ($)"),
      minWidth: 125,
    },
    {
      field: "discount",
      headerName: t("Discount ($)"),
      minWidth: 125,
      renderCell: (props) => {
        if (props.formattedValue === "icon") {
          return <Search />;
        }
      },
    },
  ];

  const chargesData = [
    {
      id: 1,
      type: "Time Fees",
      billed: "2.00",
      worked: "2.00",
      variance: "2.00",
      discount: "0.00",
    },
    {
      id: 2,
      type: "Task Fees",
      billed: "2.00",
      worked: "2.00",
      variance: "2.00",
      discount: "0.00",
    },
    {
      id: 3,
      type: "Time Fees",
      billed: "2.00",
      worked: "2.00",
      variance: "2.00",
      discount: "0.00",
    },
    {
      id: 4,
      type: "Expense",
      billed: "2.00",
      worked: "2.00",
      variance: "2.00",
      discount: "0.00",
    },
    {
      id: 5,
      type: "Total Charges",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "",
    },
    {
      id: 6,
      type: "Discount",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "icon",
    },
    {
      id: 7,
      type: "Surcharge",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "icon",
    },
    {
      id: 8,
      type: "Interest",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "icon",
    },
    {
      id: 9,
      type: "Taxes",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "icon",
    },
    {
      id: 10,
      type: "Retainer",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "",
    },
    {
      id: 11,
      type: "Total Due",
      billed: "00.00",
      worked: "00.00",
      variance: "00.00",
      discount: "",
    },
  ];

  const columns = [
    {
      field: "type",
      headerName: t(""),
      minWidth: 125,
      renderCell: (props) => {
        return <h1 className="text-sm font-bold ">{props.formattedValue}:</h1>;
      },
    },

    {
      field: "onAccount",
      headerName: t("On Account ($)"),
      minWidth: 125,
    },

    {
      field: "applied",
      headerName: t("Applied ($)"),
      minWidth: 125,
    },

    {
      field: "balance",
      headerName: t("Balance ($)"),
      minWidth: 125,
    },

    {
      field: "icon",
      headerName: t(""),
      minWidth: 125,
      renderCell: (props) => {
        if (props.formattedValue === "icon") {
          return <Search />;
        }
      },
    },
  ];

  const data = [
    {
      id: 1,
      type: "Trust",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "icon",
    },
    {
      id: 2,
      type: "Advances",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "icon",
    },
    {
      id: 3,
      type: "Retainer",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "icon",
    },
    {
      id: 4,
      type: "Net Due",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "",
    },
    {
      id: 5,
      type: "Balance",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "",
    },
    {
      id: 6,
      type: "Total A/R",
      onAccount: "00.00",
      applied: "00.00",
      balance: "00.00",
      icon: "",
    },
  ];

  return (
    <Box className="bill-summary-section">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Box className="timekeeper-section">
            <Box className="timekeeper-header">
              <span className="font-bold timekeeper-head-text">Current Charges</span>
              <Box className="timekeeper-action-btn">
                <a href="#" className="text-blue-700 underline hover:no-underline">
                  View Compliance List
                </a>
              </Box>
            </Box>
            <Box className="time-charges-table">
              <DataTable
                columns={chargesColumns ?? []}
                rows={chargesData ?? []}
                checkboxSelection={false}
                initialState={{}}
                cellClassName={(params) => (params.field !== "type" ? { textAlign: "right" } : "")}></DataTable>
            </Box>
          </Box>

          <Box className="time-charges-account-table">
            <DataTable columns={columns ?? []} rows={data ?? []} checkboxSelection={false} initialState={{}}></DataTable>
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Box className="timekeeper-section">
            <Box className="timekeeper-header">
              <span className="timekeeper-head-text">Timekeeper Recap [01] :</span>
              <Box className="timekeeper-action-btn">
                <Button variant="outlined" className="btn btn-small" disabled startIcon={<UploadFileIcon />}>
                  Upload Attachment
                </Button>
                <Button variant="outlined" className="btn btn-small" disabled onClick={() => setExportDialog(true)}>
                  Export to Excel
                </Button>
                <Button variant="outlined" className="btn btn-small" disabled onClick={() => setFeeDialog(true)}>
                  Add Simple Fee
                </Button>
                <Button variant="outlined" className="btn btn-small" disabled>
                  Add Existing Fee
                </Button>
              </Box>
            </Box>
            <Box className="timekeeper-table">
              <DataTable columns={timekeeperColumns ?? []} rows={timeKeeperData ?? []} checkboxSelection={false} initialState={{}}></DataTable>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <AlertDialog
        open={isExportDialogOpen}
        onClose={setExportDialog}
        heading="Export to Excel"
        content="Are you sure you want to export this recap to Excel?"
        declineText="No"
        acceptText="Yes"
      />

      <AlertDialog
        open={isDeleteDialogOpen}
        onClose={setDeleteDialog}
        heading="Confirmation"
        content="Are you sure you want to delete the recap entries?"
        declineText="No"
        acceptText="Yes"
      />

      <Dialog className="form-dialog" maxWidth="md" open={isFeeDialogOpen} onClose={() => setFeeDialog(false)}>
        <Box className="form-dialog-heading">
          <DialogTitle>Add Simple Fee</DialogTitle>
          <IconButton onClick={() => setFeeDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="form-dialog-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField label="Client/Matter *" value="0020 Buck’s County Coffee Co- 0046 Rojo’s Roaster Partnership" />
            </Grid>
            <Grid item xs={4}>
              <DatePicker label="Date *" value="03/05/2023" />
            </Grid>
            <Grid item xs={4}>
              <TextField label="Duration *" value="0.5" />
            </Grid>
            <Grid item xs={4}>
              <TextField label="Total" disabled value="$50.00" />
            </Grid>
            <Grid item xs={12}>
              <TextArea label="Narrative *" value="Narrative goes here." row={1.5} />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button className="btn btn-small back-btn" onClick={() => setFeeDialog(false)}>
            Back to Billing Summary
          </Button>
          <Box className="submit-cancel-btn-box">
            <Button className="btn btn-small decline-btn" variant="outlined" onClick={() => setFeeDialog(false)}>
              Cancel
            </Button>
            <Button className="btn btn-small accept-btn">Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Summary;
