import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/components/AlertDialog.scss";

const AlertDialog = ({ open, onClose, heading, content, declineText, acceptText }) => {
  return (
    <Dialog className="alert-dialog" maxWidth="md" open={open} onClose={() => onClose(false)}>
      <Box className="alert-dialog-heading">
        <DialogTitle>{heading}</DialogTitle>
        <IconButton onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button className="btn btn-small decline-btn" variant="outlined" onClick={() => onClose(false)}>
          {declineText}
        </Button>
        <Button className="btn btn-small accept-btn">{acceptText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
