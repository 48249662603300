import { gql } from "@apollo/client";

export const GET_BILL_DETAILS = gql`
  query GetBillDetail($billId: Int!) {
    getBillDetail(billId: $billId) {
      id
      unpostBillCount
      referenceNumber
      clientId
      clientCode
      clientName
      matterDtoList {
        jobId
        jobCode
        jobName
        billAttornyDto {
          billAttrId
          billAttrCode
          billAttrName
        }
      }
      total
      balance
      ownerId
      ownerCode
      ownerName
      billFormat
      billScheme
      eBillStatusTypeID
      eBillStatusTypeDesc
      billPhaseID
      billPhaseDesc
      billStatusId
      billStatusDesc
      billDate
      billPeriod
      startDate
      cuttOffDate
      dueDate
      billPeriodStatusId
      billPeriodStatusDesc
      billRevisionNum
    }
  }
`;
