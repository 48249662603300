import { gql } from "@apollo/client";

export const ADD_DIARY_MESSAGE = gql`
  mutation AddDiaryMessage($billId: Int!, $msgText: String!) {
    addNewBillDiaryMsg(billId: $billId, msgText: $msgText) {
      id
      billId
      employeeId
      messageDate
      messageText
    }
  }
`;
