import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey("b5aaed8b75e9139e4d22473261d34883Tz02NDgwNCxFPTE3MTM1MzkwODg2MzAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const DataTable = ({
  columns,
  rows,
  components,
  loader,
  initialState,
  onCellDoubleClick,
  isRowSelectable,
  pinnedRows,
  checkboxSelection,
  ...props
}) => {
  return (
    <DataGridPro
      className={`billing-data-grid ${rows.length === 0 && "empty-billing-grid"}`}
      sx={{ fontSize: "14px", background: "white" }}
      rows={rows || []}
      columns={columns || []}
      loader={loader}
      hideFooterSelectedRowCount
      checkboxSelection={checkboxSelection}
      disableColumnMenu
      disableRowSelectionOnClick
      sortingOrder={["desc", "asc"]}
      isRowSelectable={isRowSelectable}
      pinnedRows={pinnedRows}
      onRowDoubleClick={onCellDoubleClick}
      pagination
      initialState={initialState}
      components={components}
      {...props}
    />
  );
};

export default DataTable;
