import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Pending Activity Grid",
};
const pendingActivitySlice = createSlice({
  name: "pendingActivity",
  initialState,
  reducers: {
    showMessage: () => {},
    hideMessage: () => {},
  },
});

export const { hideMessage, showMessage } = pendingActivitySlice.actions;
export const selectData = (state) => state.Billing.pendingActivity;

export default pendingActivitySlice.reducer;
