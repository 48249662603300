import React, { memo } from "react";
import TextField from "@mui/material/TextField";

const TextArea = ({ label, disabled, value, row, onChange }) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <TextField
      className="text-area-component"
      value={value}
      fullWidth
      disabled={disabled}
      label={label}
      autoComplete="off"
      InputLabelProps={{
        shrink: true,
      }}
      rows={row}
      onChange={handleChange}
      multiline
    />
  );
};

export default memo(TextArea);
