import React, { useState } from "react";
import { TabContext, TabList } from "@mui/lab";
import TextField from "../../Components/CommonComponent/TextField";
import TextArea from "../../Components/CommonComponent/TextArea";
import LaunchIcon from "@mui/icons-material/Launch";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BILL_TABS, OWNER_TABS } from "../../utils/enum";
import { ADD_DIARY_MESSAGE } from "../../pages/Billing/graphql/ADD_DIARY_MESSAGE";
import { useMutation } from "@apollo/client";
import { Box, Button, Grid, Tab } from "@mui/material";
import { currencyFormatter } from "../../utils/currencyFormat";
import dayjs from "dayjs";

const BillDetail = ({ billDetails, ownerTab, setOwnerTab, isBillDetailOpen, setBillDetailOpen }) => {
  const [message, setMessage] = useState("");

  // Define mutation function
  const [addDiaryMessage] = useMutation(ADD_DIARY_MESSAGE);

  // Function to handle adding a diary message
  const handleAddMessage = () => {
    addDiaryMessage({
      variables: {
        billId: billDetails?.id,
        msgText: message,
      },
    })
      .then((result) => {
        // Handle the result if needed
        console.log("Message added:", result);
        // Optionally, you can update the UI or perform other actions after adding the message
      })
      .catch((error) => {
        // Handle errors if the mutation fails
        console.error("Error adding message:", error);
      });
  };

  return (
    <TabContext value={ownerTab}>
      <Box className="bill-tab-section" sx={{ borderBottom: isBillDetailOpen ? 1 : 0, borderColor: "divider" }}>
        {isBillDetailOpen ? (
          <TabList onChange={(event, newValue) => setOwnerTab(newValue)}>
            {Object.values(OWNER_TABS).map((item, index) => {
              return <Tab key={index} label={item.label} value={item.value} />;
            })}
          </TabList>
        ) : (
          <div></div>
        )}
        <TabList>
          <Tab
            label={BILL_TABS.BILL_DETAILS}
            icon={isBillDetailOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            iconPosition="end"
            onClick={() => setBillDetailOpen(!isBillDetailOpen)}
          />
          <Tab label={BILL_TABS.FEES_AVAILABLE} icon={<LaunchIcon />} iconPosition="end" />
          <Tab label={BILL_TABS.EXPENSE_AVAILABLE} icon={<LaunchIcon />} iconPosition="end" />
          <Tab label={BILL_TABS.PAYMENTS_AVAILABLE} icon={<LaunchIcon />} iconPosition="end" />
        </TabList>
      </Box>
      {/* Bill Detail Tab Content */}
      {isBillDetailOpen && (
        <Box className="bill-content-section">
          <Grid container spacing={4.75}>
            <Grid item xs={ownerTab === OWNER_TABS.OWNER_DETAILS.value ? 4 : 6}>
              <Box className="flex flex-col gap-[8px]">
                <TextField label="Bill Number" disabled value={billDetails?.id} />
                <TextField label="Reference" disabled value={billDetails?.referenceNumber || "None"} />
                <TextField
                  label="Client & Matter"
                  disabled
                  value={`${billDetails?.clientCode ? billDetails?.clientCode : ""} ${billDetails?.clientName ? billDetails?.clientName : ""} - ${
                    billDetails?.matterDtoList?.[0]?.jobCode ? billDetails?.matterDtoList?.[0]?.jobCode : ""
                  } ${billDetails?.matterDtoList?.[0]?.jobName ? billDetails?.matterDtoList?.[0]?.jobName : ""}`}
                />
                <TextField label="Bill Attorney" disabled value={billDetails?.matterDtoList?.[0]?.billAttornyDto?.billAttrName} />
                <Box className="flex gap-[16px]">
                  <TextField label="Total" disabled value={currencyFormatter(billDetails?.total)} />
                  <TextField label="Balance" disabled value={currencyFormatter(billDetails?.balance)} />
                </Box>
              </Box>
            </Grid>
            {ownerTab === OWNER_TABS.OWNER_DETAILS.value && (
              <>
                <Grid item xs={4}>
                  <Box className="flex flex-col gap-[8px]">
                    <TextField label="Owner" disabled value={billDetails?.ownerName || "None"} />
                    <TextField label="Bill Format" disabled value={billDetails?.billFormat || "None"} />
                    <TextField label="Bill Scheme" disabled value={billDetails?.billScheme || "None"} />
                    <TextField label="E-Bill Status" disabled value={billDetails?.eBillStatusTypeDesc || "None"} />
                    <TextField label="Phase" disabled value={billDetails?.billPhaseDesc || "None"} />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="flex flex-col gap-[8px]">
                    <TextField label="Status" disabled value={billDetails?.billStatusDesc || "None"} />
                    <TextField
                      label="Bill Date"
                      disabled
                      value={billDetails?.billDate ? dayjs(billDetails?.billDate, "YYYY-MM-DD").format("MM/DD/YYYY") : "None"}
                    />
                    <TextField label="Period" disabled value={billDetails?.billPeriod + " " + billDetails?.billPeriodStatusDesc || "None"} />
                    <Box className="flex gap-[16px]">
                      <TextField
                        label="Start Date"
                        disabled
                        value={billDetails?.startDate ? dayjs(billDetails?.startDate, "YYYY-MM-DD").format("MM/DD/YYYY") : "None"}
                      />
                      <TextField
                        label="Cutoff Date"
                        disabled
                        value={billDetails?.startDate ? dayjs(billDetails?.cuttOffDate, "YYYY-MM-DD").format("MM/DD/YYYY") : "None"}
                      />
                      <TextField
                        label="Due Date"
                        disabled
                        value={billDetails?.startDate ? dayjs(billDetails?.dueDate, "YYYY-MM-DD").format("MM/DD/YYYY") : "None"}
                      />
                    </Box>
                    <Box className="flex gap-[16px]">
                      <Button disabled variant="outlined" className="btn btn-small btn-status">
                        Lock
                      </Button>
                      <Button disabled variant="outlined" className="btn btn-small btn-status">
                        Finalize
                      </Button>
                      <Button disabled variant="outlined" className="btn btn-small btn-status">
                        Send
                      </Button>
                      <Button disabled variant="outlined" className="btn btn-small btn-status">
                        Post
                      </Button>
                      <Button disabled variant="outlined" className="btn btn-small btn-status">
                        Write-Off
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
            {ownerTab === OWNER_TABS.REVIEW_NOTES.value && (
              <Grid item xs={6}>
                <Box className="flex flex-col gap-[11px]">
                  <TextArea
                    label="Review Notes"
                    value="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                    row={3.5}
                  />
                  <Box className="flex gap-[16px]">
                    <Button variant="outlined" className="btn btn-small btn-status">
                      Ready To Bill
                    </Button>
                    <Button variant="outlined" className="btn btn-small btn-status">
                      Hold
                    </Button>
                    <Button variant="outlined" className="btn btn-small btn-status">
                      Transfer
                    </Button>
                    <Button variant="outlined" className="btn btn-small btn-status">
                      Reallocate
                    </Button>
                    <Button variant="outlined" className="btn btn-small btn-status">
                      Write-Off
                    </Button>
                    <Button variant="outlined" className="btn btn-small btn-status">
                      AR Statement
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
            {ownerTab === OWNER_TABS.DIARY_MESSAGES.value && (
              <Grid item xs={6}>
                <Box className="flex flex-col gap-[11px]">
                  <TextArea label="Review Notes" value={message} onChange={(e) => setMessage(e.target.value)} row={3.5} />
                  <Box className="flex justify-end gap-[16px]">
                    <Box className="flex w-28">
                      <Button variant="outlined" className="btn btn-small btn-status" onClick={handleAddMessage}>
                        Add Message
                      </Button>
                    </Box>
                    <Box className="flex w-20">
                      <Button variant="outlined" className="btn btn-small btn-status">
                        Preview
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </TabContext>
  );
};

export default BillDetail;
